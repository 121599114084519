import { FormContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import {
  FormState,
  ServiceData,
  SlotService,
} from '../../../../utils/state/initialStateFactory';
import { BOOKINGS_APP_DEF_ID } from '@wix/bookings-adapter-ooi-wix-sdk';
import { ErrorHandlers } from '../errorHandlers/errorHandlers';
import { isValidEmail } from '../../../../utils/form-validations';
import { LineItem } from '@wix/ambassador-totals-calculator/types';
import { AppliedDiscount } from '@wix/ambassador-totals-calculator/http';
import { FormStatus } from '../../../../types/form-state';
import { isFixedPrice } from '../../../../utils/payment';
import { PriceInfo } from '@wix/ambassador-bookings-v2-price-info/types';
import {
  mapPriceInfoToPaymentDetails,
  mapVariantsToLineItems,
} from '../../../../utils/mappers/dynamic-price.mapper';
import { FormApi } from '../../../../api/FormApi';
import { SelectedVariants } from '@wix/bookings-uou-types';
import { getServiceSlotIdentifier, mapToArray } from '../../../../utils';
import { generateCouponScope } from '../../../../consts/coupon';

export type CalculatePaymentDetails = ({
  couponCode,
}: {
  couponCode?: string;
}) => Promise<void>;

export function createCalculatePaymentDetailsAction({
  actionFactoryParams,
  errorHandlers,
}: {
  actionFactoryParams: ActionFactoryParams<FormState, FormContext>;
  errorHandlers: ErrorHandlers;
}): CalculatePaymentDetails {
  return async ({ couponCode }) => {
    const [state, setState] = actionFactoryParams.getControllerState();
    const { formApi, reportError } = actionFactoryParams.context;
    const {
      formInputs: { numberOfParticipants, email },
      isBookingsOnEcom,
      businessInfo,
    } = state;

    setState({
      status: FormStatus.PROCESSING_PAYMENT_DETAILS,
    });

    try {
      if (isBookingsOnEcom) {
        const priceInfoList: {
          [key: string]: PriceInfo;
        } = {};
        let updatedServiceData: ServiceData = { ...state.serviceData };

        await Promise.all(
          mapToArray<SlotService>(state.serviceData.slotServices).map(
            async (slotService) => {
              const { nestedSlot, dynamicPriceInfo } = slotService;
              const identifer = getServiceSlotIdentifier(
                slotService.nestedSlot,
              );
              const priceInfo = await previewPrice({
                serviceId: nestedSlot.serviceId,
                resourceId: nestedSlot.resource?.id!,
                selectedVariants: dynamicPriceInfo?.selectedVariants,
                formApi,
              });

              if (priceInfo?.bookingLineItems?.length) {
                const dynamicPriceOptions =
                  dynamicPriceInfo?.serviceOptionsAndVariants?.options!;
                const locale = businessInfo.dateRegionalSettingsLocale!;
                const paymentDetails = mapPriceInfoToPaymentDetails({
                  priceInfo,
                  dynamicPriceOptions,
                  locale,
                });

                priceInfoList[identifer] = priceInfo;

                updatedServiceData = {
                  ...state.serviceData,
                  slotServices: {
                    ...state.serviceData.slotServices,
                    [identifer]: {
                      ...state.serviceData.slotServices[identifer],
                      dynamicPriceInfo: {
                        ...state.serviceData.slotServices[identifer]
                          .dynamicPriceInfo,
                        paymentDetails,
                      },
                    },
                  },
                };
              }
            },
          ),
        );

        setState({ serviceData: updatedServiceData });

        const lineItems: LineItem[] = [];

        mapToArray<SlotService>(state.serviceData.slotServices).forEach(
          (slotService) => {
            const {
              service,
              paymentDetails,
              dynamicPriceInfo,
              selectedPaymentType,
              nestedSlot,
            } = slotService;
            const priceInfo =
              priceInfoList[getServiceSlotIdentifier(slotService.nestedSlot)];
            let price: number;
            let deposit: number | undefined;
            const isDynamicPrice = !!priceInfo?.calculatedPrice;

            if (priceInfo?.bookingLineItems?.length) {
              price =
                Number(priceInfo?.calculatedPrice) ||
                Number(dynamicPriceInfo?.defaultPrice?.amount!);
              deposit = priceInfo?.deposit!;
            } else {
              price = service.payment.paymentDetails.price;
              deposit = paymentDetails.minCharge;
            }

            const paymentOption = formApi.getPaymentOption(
              selectedPaymentType,
              deposit,
            );

            lineItems.push({
              id: nestedSlot.lineItemId,
              catalogReference: {
                catalogItemId: service.id,
                appId: BOOKINGS_APP_DEF_ID,
              },
              price: String(
                price * (isDynamicPrice ? 1 : numberOfParticipants),
              ),
              ...(deposit
                ? {
                    depositAmount: String(
                      deposit * (isDynamicPrice ? 1 : numberOfParticipants),
                    ),
                  }
                : {}),
              quantity: 1,
              couponScopes: [generateCouponScope(service.id)],
              paymentOption,
            });
          },
        );

        const { priceSummary, appliedDiscounts, payNow, payLater } =
          await formApi.calculateTotalPrice({
            lineItems,
            couponCode,
            email: isValidEmail(email) ? email : undefined,
            onError: (error) => {
              errorHandlers.addError(error);
            },
          });

        if (!appliedDiscounts) {
          setState({ status: FormStatus.IDLE });
          return;
        }

        const [couponDiscount] = appliedDiscounts!;
        const slotServicesTotalPrice = mapToArray<SlotService>(
          state.serviceData.slotServices,
        ).reduce((partialSum, a) => partialSum + a.paymentDetails.price, 0);

        setState({
          couponInfo: {
            ...state.couponInfo,
            appliedCoupon: mapCouponDiscountToAppliedCoupon(couponDiscount),
          },
          serviceData: {
            ...state.serviceData,
            summaryPaymentDetails: {
              subtotalPerParticipant:
                Number(priceSummary?.subtotal?.amount) / numberOfParticipants,
              tax: Number(priceSummary?.tax?.amount),
              totalPrice: priceSummary?.total?.amount
                ? Number(priceSummary?.total?.amount)
                : slotServicesTotalPrice,
              payNow: Number(payNow?.total?.amount),
              payLater: payLater?.total?.amount
                ? Number(payLater?.total?.amount)
                : state.serviceData.summaryPaymentDetails.payLater,
            },
          },
          status: FormStatus.IDLE,
        });
      } else {
        // hard coded index 0 due to is non ecom and it about to be removed
        const slotService = mapToArray<SlotService>(
          state.serviceData.slotServices,
        )[0];
        const { service, nestedSlot, paymentDetails } = slotService;
        const { id: serviceId, rate, payment } = service;
        const slot = nestedSlot;

        const paymentsDetails = await formApi.getPaymentsDetails({
          slot,
          numberOfParticipants,
          rate,
          serviceId,
          couponCode,
          email,
          isFixedPrice: isFixedPrice(payment),
          onError: (error) => {
            errorHandlers.addError(error);
          },
        });

        if (!paymentsDetails) {
          setState({ status: FormStatus.IDLE });
          return;
        }

        const appliedCoupon = paymentsDetails?.couponDetails;
        const finalPrice = paymentsDetails?.finalPrice;

        setState({
          couponInfo: {
            ...state.couponInfo,
            appliedCoupon,
          },
          serviceData: {
            ...state.serviceData,
            summaryPaymentDetails: {
              ...state.serviceData.summaryPaymentDetails,
              totalPrice: finalPrice?.amount
                ? Number(finalPrice?.amount)
                : paymentDetails.price,
            },
            slotServices: {
              ...state.serviceData.slotServices,
              [getServiceSlotIdentifier(slotService.nestedSlot)]: {
                ...slotService,
                paymentDetails: {
                  ...paymentDetails,
                  minCharge: finalPrice?.downPayAmount
                    ? Number(finalPrice.downPayAmount)
                    : paymentDetails.minCharge,
                },
              },
            },
          },
          status: FormStatus.IDLE,
        });
      }
    } catch (error) {
      errorHandlers.addError(error as any);
      setState({ status: FormStatus.IDLE });
      reportError(error as any);
    }
  };
}

async function previewPrice({
  formApi,
  serviceId,
  resourceId,
  selectedVariants,
}: {
  formApi: FormApi;
  serviceId: string;
  resourceId: string;
  selectedVariants?: SelectedVariants[];
}): Promise<PriceInfo> {
  if (selectedVariants) {
    const bookingLineItems = mapVariantsToLineItems({
      serviceId,
      resourceId,
      selectedVariants,
    });
    if (bookingLineItems.length) {
      const previewPriceResponse = await formApi.previewPrice({
        bookingLineItems,
      });
      return previewPriceResponse?.priceInfo!;
    }
  }
  return {};
}

function mapCouponDiscountToAppliedCoupon(couponDiscount?: AppliedDiscount) {
  const coupon = couponDiscount?.coupon;
  return coupon
    ? {
        couponCode: coupon?.code,
        couponDiscount: coupon?.amount?.amount,
        couponId: coupon?.id,
        couponName: coupon?.name,
      }
    : undefined;
}
