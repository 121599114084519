import { BenefitWithPlanInfo } from '@wix/ambassador-pricing-plans-v2-benefit/types';
import { ServicePayment, OfferedAsType } from '@wix/bookings-uou-types';
import {
  PaymentOption,
  PaymentType,
  PlanWithDescription,
  ReservedPaymentOptionIds,
  ReservedPaymentOptionLabels,
  TFunction,
} from '../../../types/types';
import { Membership } from '@wix/ambassador-memberships-spi-host/types';
import { DateTimeFormatter } from '@wix/bookings-date-time';

export const isOfferedAsOneTimeOnly = (servicePayment: ServicePayment) => {
  return (
    servicePayment.offeredAs.length === 1 && isOfferedAsOneTime(servicePayment)
  );
};

export const isOfferedAsOneTime = (servicePayment: ServicePayment) => {
  return servicePayment.offeredAs.includes(OfferedAsType.ONE_TIME);
};

export const isOfferedAsPricingPlanOnly = (servicePayment: ServicePayment) => {
  return (
    servicePayment.offeredAs.length <= 1 &&
    isServiceHavePricingPlans(servicePayment)
  );
};

export const isServiceHavePricingPlans = (servicePayment: ServicePayment) => {
  return servicePayment.pricingPlanInfo?.pricingPlans?.length! > 0;
};

export const isOfferedAsPricingPlanOnlyWithoutPlansConnected = (
  servicePayment: ServicePayment,
) => {
  return (
    servicePayment.offeredAs.length === 1 &&
    servicePayment.offeredAs[0] === OfferedAsType.PRICING_PLAN &&
    !isServiceHavePricingPlans(servicePayment)
  );
};

export const createBuyPlanOption = (plan: PlanWithDescription) => {
  return {
    id: plan.id!,
    label: plan.name!,
    disabled: false,
    type: PaymentType.BUY_PLAN,
    pricingDetails: plan.description,
  };
};

export const createPayOfflineOption = () => {
  return {
    id: ReservedPaymentOptionIds.SingleSession,
    label: ReservedPaymentOptionLabels.OFFLINE,
    disabled: false,
    type: PaymentType.SINGLE_SESSION,
  };
};

export const createPayOnlineOption = () => {
  return {
    id: ReservedPaymentOptionIds.SingleSession,
    label: ReservedPaymentOptionLabels.ONLINE,
    disabled: false,
    type: PaymentType.SINGLE_SESSION,
  };
};

export function createUsePlanOption(
  membership: Membership,
  numberOfParticipants: number,
  benefitsWithPlanInfo: BenefitWithPlanInfo[] | undefined,
  t: TFunction,
  dateAndTimeFormatter: DateTimeFormatter,
): PaymentOption {
  const disabled = isDisabledMembership({
    membership,
    numberOfParticipants,
  });
  return {
    id: membership.id!,
    label: membership.name?.original!,
    benefitInfo: findBenefitWithPlanInfo(
      membership.additionalData?.benefitId,
      benefitsWithPlanInfo,
    ),
    disabled,
    ...(membership?.credits?.remaining && membership?.credits?.total
      ? {
          suffix: membership.credits.remaining + '/' + membership.credits.total,
        }
      : {}),
    ...(membership.expirationDate
      ? {
          validUntil: t('app.payment.valid-until.text', {
            validUntil: dateAndTimeFormatter.formatDate(
              membership.expirationDate.toISOString(),
            ),
          }),
        }
      : {}),
    ...(membership?.credits?.remaining && {
      creditRemain: membership.credits.remaining,
    }),
    type: PaymentType.USE_PLAN,
  };
}

export const findBenefitWithPlanInfo = (
  benefitId?: string,
  benefitsWithPlanInfo?: BenefitWithPlanInfo[],
) => {
  return benefitsWithPlanInfo?.find((item) => item?.benefit?.id === benefitId);
};

export const isDisabledMembership = ({
  membership,
  numberOfParticipants,
}: {
  membership: Membership;
  numberOfParticipants: number;
}) => {
  const isUnlimitedMembership = !membership?.credits?.remaining;
  return (
    Number(membership?.credits?.remaining) < numberOfParticipants ||
    (isUnlimitedMembership && numberOfParticipants > 1)
  );
};
