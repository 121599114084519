import { CreateActionParams } from '../actions';
import { bookingsBookingsFormLoad } from '@wix/bi-logger-wixboost-ugc/v2';
import { FormStatus } from '../../../../types/form-state';
import { FormApi } from '../../../../api/FormApi';
import { IUser, ReportError } from '@wix/yoshi-flow-editor';
import { FormErrorsType } from '../../../../types/errors';
import { OnError } from '../../../../api/types';
import { ReferralInfo } from '../../../../types/biLoggerTypes';

export type InitializeWidget = () => void;

export function createInitializeWidgetAction({
  getControllerState,
  internalActions: { calculatePaymentDetails, errorHandlers },
  context: { biLogger, formApi, user, reportError },
  flowApi,
}: CreateActionParams): InitializeWidget {
  return async () => {
    const [state, setState] = getControllerState();
    const {
      isBookingsOnEcom,
      isMemberAreaInstalled,
      isCart,
      isServiceInCart,
      serviceData,
    } = state;

    biLogger?.initialDefaultBiLogger(state);

    biLogger?.report(
      bookingsBookingsFormLoad({
        formId: serviceData.formSchema.formId,
        ...(isCart
          ? {
              ...(isServiceInCart
                ? { referralInfo: ReferralInfo.AddMoreSessions }
                : {}),
              emptyCart: !isServiceInCart,
            }
          : {}),
      }),
    );

    setState({
      status: FormStatus.IDLE,
    });
    if (isBookingsOnEcom) {
      await calculatePaymentDetails({});
    }
    const onError = (error: FormErrorsType) => errorHandlers.addError(error);
    const memberDetails = await initMemberDetails({
      isMemberAreaInstalled,
      formApi,
      user,
      onError,
      reportError,
    });
    setState({
      memberDetails,
    });
  };
}

const initMemberDetails = async ({
  isMemberAreaInstalled,
  formApi,
  user,
  onError,
  reportError,
}: {
  isMemberAreaInstalled: boolean;
  formApi: FormApi;
  user: IUser;
  onError: OnError;
  reportError: ReportError;
}) => {
  const isLoggedInUser = user.loggedIn;
  const shouldGetMemberDetails = isMemberAreaInstalled && isLoggedInUser;
  const memberDetails = shouldGetMemberDetails
    ? await formApi
        .getMemberDetails({
          id: user.id,
          onError,
        })
        .catch((e) => {
          onError(e);
          reportError(e);
          return undefined;
        })
    : undefined;
  return memberDetails;
};
