import { IWidgetControllerConfig } from '@wix/native-components-infra/dist/src/types/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  ActionsFactory,
  SetState,
  StateChangeCallback,
  GetControllerState,
} from './ControlledComponent.types';

export async function createControlledComponent<
  ControllerStateType,
  ControllerActionTypes,
  ContextType,
>({
  flowApi,
  controllerConfig,
  initialState,
  actionsFactory,
  context,
}: {
  flowApi: ControllerFlowAPI;
  controllerConfig: IWidgetControllerConfig;
  initialState: ControllerStateType;
  actionsFactory: ActionsFactory<
    ControllerStateType,
    ControllerActionTypes,
    ContextType
  >;
  context: ContextType;
}): Promise<{
  onStateChange: (callback: StateChangeCallback<ControllerStateType>) => void;
  render: (
    propsToUpdate: Partial<
      ControllerStateType | { actions: ControllerActionTypes }
    >,
  ) => void;
  actions: ControllerActionTypes;
}> {
  const { setProps } = controllerConfig;
  const stateChangedListeners: StateChangeCallback<ControllerStateType>[] = [];

  const stateProxy = new Proxy<any>(initialState, {
    get(target, prop) {
      return target[prop];
    },
    set(target, prop, value) {
      if (
        typeof prop === 'string' &&
        (Array.isArray(target[prop]) || typeof target[prop] === 'object') &&
        target[prop] !== null
      ) {
        target[prop] = Array.isArray(target[prop])
          ? [...target[prop], ...value]
          : { ...target[prop], ...value };
      } else {
        target[prop] = value;
      }
      return true;
    },
  });

  const updateState = (stateToUpdate: Partial<ControllerStateType>) => {
    Object.assign(stateProxy, stateToUpdate);
  };

  const onStateChange = (
    callback: StateChangeCallback<ControllerStateType>,
  ) => {
    stateChangedListeners.push(callback);
  };

  const notifyStateChangedListeners = (newState: ControllerStateType) => {
    stateChangedListeners.forEach((listener) => listener(stateProxy));
  };

  const setState: SetState<ControllerStateType> = (
    stateToUpdate: Partial<ControllerStateType>,
  ) => {
    updateState(stateToUpdate);
    render(stateToUpdate);
    notifyStateChangedListeners(stateProxy);
  };

  const getControllerState: GetControllerState<ControllerStateType> = () => {
    return [stateProxy, setState];
  };

  const controllerActions = actionsFactory({
    getControllerState,
    context,
    flowApi,
  });

  const render = (
    propsToUpdate: Partial<
      ControllerStateType | { actions: ControllerActionTypes }
    > = stateProxy,
  ) => {
    setProps({
      ...propsToUpdate,
    });
  };

  render({
    ...initialState,
    actions: controllerActions,
    fitToContentHeight: true,
  });

  return { onStateChange, render, actions: controllerActions };
}
