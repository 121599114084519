export const ExperimentsConsts = {
  AlwaysShowComplexPhoneField: 'specs.bookings.AlwaysShowComplexPhoneField',
  FixFormUoUCheckboxLink: 'specs.bookings.FixFormUoUCheckboxLink',
  DayfulOverrideEcomContinueShoppingUrl:
    'specs.bookings.DayfulOverrideEcomContinueShoppingUrl',
  Cart: 'specs.bookings.cart',
  FixWidgetsNavigationAfterCheckoutWasRemoved:
    'specs.bookings.FixWidgetsNavigationAfterCheckoutWasRemoved',
  HideCouponInFormPage: 'specs.bookings.HideCouponInFormPage',
  AddNewBiEvents: 'specs.bookings.AddNewBiEvents',
  CartBookNowSettings: 'specs.bookings.CartBookNowSettings',
  CancellationPolicyChangeAPI: 'specs.bookings.CancellationPolicyChangeAPI',
  SendAddressToEcomCheckoutAndCart:
    'specs.bookings.sendAddressToEcomCheckoutAndCart',
  ChangePricingPlanNavigationToNewApi:
    'specs.bookings.ChangePricingPlanNavigationToNewApi',
  SaveSubmissionWithDataCapsule: 'specs.bookings.SaveSubmissionWithDataCapsule',
  HidePriceWhenBuyinAPlanInDynamicPriceDropdown:
    'specs.bookings.HidePriceWhenBuyinAPlanInDynamicPriceDropdown',
  ViewCartButtonWiredToSecondary:
    'specs.bookings.ViewCartButtonWiredToSecondary',
  AppSectionParamsAdapter: 'specs.bookings.AppSectionParamsAdapter',
  BookingsFormWidgetCssOptimization:
    'specs.bookings.BookingsFormWidgetCssOptimization',
  EditorOOIFormPage: 'specs.bookings.EditorOOIFormPage',
  AwaitForPPNavigation: 'specs.bookings.AwaitForPPNavigation',
  AddTimeZoneToContactDetails: 'specs.bookings.AddTimeZoneToContactDetails',
  PreventConflictsOnCart: 'specs.bookings.PreventConflictsOnCart',
  DisableFormValidationWhenPurchasingAPricingPlan:
    'specs.bookings.DisableFormValidationWhenPurchasingAPricingPlan',
  DeleteFullAddressFromCollapseFormValues:
    'specs.bookings.DeleteFullAddressFromCollapseFormValues',
  MultiServiceAppointment: 'specs.bookings.multiServiceAppointment',
  FormPaymentOptionNewDropdown: 'specs.bookings.FormPaymentOptionNewDropdown',
  AddShouldCallConsistantQueryToGetScheduleAvailability:
    'specs.bookings.AddShouldCallConsistantQueryToGetScheduleAvailability',
  queryServiceOptionsAndVariants:
    'specs.bookings.queryServiceOptionsAndVariants',
  SplitJapaneseNamesOnForm: 'specs.bookings.SplitJapaneseNamesOnForm',
};
